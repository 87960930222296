export const colorConfig = [
  { value: 'black', label: 'Black', color: '#000000' },
  { value: 'brown', label: 'Brown', color: '#8B4513' },
  { value: 'grey', label: 'Grey', color: '#808080' },
  { value: 'beige', label: 'Beige', color: '#F5F5DC' },
  { value: 'pink', label: 'Pink', color: '#FFC0CB' },
  { value: 'purple', label: 'Purple', color: '#800080' },
  { value: 'red', label: 'Red', color: '#FF0000' },
  { value: 'yellow', label: 'Yellow', color: '#FFFF00' },
  { value: 'blue', label: 'Blue', color: '#0000FF' },
  { value: 'green', label: 'Green', color: '#008000' },
  { value: 'orange', label: 'Orange', color: '#FFA500' },
  { value: 'white', label: 'White', color: '#ccc' },
  { value: 'silver', label: 'Silver', color: '#C0C0C0' },
  { value: 'gold', label: 'Gold', color: '#FFD700' },
  { value: 'multi', label: 'Multi', color: '#808080' },
  { value: 'nude', label: 'Nude', color: '#F5DEB3' },
  { value: 'metallic', label: 'Metallic', color: '#D3D3D3' },
  // { value: 'khaki', label: 'Khaki', color: '#C3B091' },
  // { value: 'turquoise', label: 'Turquoise', color: '#40E0D0' },
  { value: 'cream', label: 'Cream', color: '#FFFDD0' },
  // { value: 'apricot', label: 'Apricot', color: '#FBCEB1' },
  { value: 'coral', label: 'Coral', color: '#FF7F50' },
  { value: 'burgundy', label: 'Burgundy', color: '#800020' },
  { value: 'rose', label: 'Rose', color: '#FF007F' },
  { value: 'lilac', label: 'Lilac', color: '#C8A2C8' },
  { value: 'light-blue', label: 'Light blue', color: '#ADD8E6' },
  { value: 'navy', label: 'Navy', color: '#000080' },
  { value: 'dark-green', label: 'Dark green', color: '#006400' },
  // { value: 'mustard', label: 'Mustard', color: '#FFDB58' },
  // { value: 'mint', label: 'Mint', color: '#98FF98', isFixed: true },
];
