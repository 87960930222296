/**
 * We use this format
 * "Category_id": ["XS", "S", "M", "L", "XL", "XXL", "XXXL"]
 * if we can't find a category we use the default
 * "default": ["XS", "S", "M", "L", "XL", "XXL", "XXXL"]
 */

export const defaultSizing = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
export const defaultLargeSizing = ['4XL', '5XL', '6XL', '7XL', '8XL'];

// these are categories that don't need sizing
export const blackListedCategoryIds = ['womens_accessories', 'men_accessories'];

export const sizing = {
  default: [...defaultSizing, ...defaultLargeSizing],
  womens_clothing: [
    '00',
    'XXXS',
    '0',
    'XXS',
    '2',
    'XS',
    '4',
    'S',
    '6',
    'S',
    '8',
    'M',
    '10',
    'M',
    '12',
    'L',
    '14',
    'L',
    '16',
    'XL',
    '18',
    'XL',
    '20',
    'XXL',
    '22',
    '24',
    'XXXL',
    '26',
    '4XL',
    'One Size',
  ],
  'womens_clothing>jeans': [
    '00',
    'XXXS',
    '0',
    'XXS',
    '2',
    'XS',
    '4',
    'S',
    '6',
    'S',
    '8',
    'M',
    '10',
    'M',
    '12',
    'L',
    '14',
    'L',
    '16',
    'XL',
    '18',
    'XL',
    '20',
    'XXL',
    '22',
    '24',
    'XXXL',
    '26',
    '4XL',
    'One Size',
  ],
  womens_shoes: [
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '14.5',
    '15',
    '15.5',
    'One Size',
  ],
  men_shoes: [
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '14.5',
    '15',
    '15.5',
    '16',
    '16.5',
    '17',
    '17.5',
    'One Size',
  ],
  'men_clothing>jeans': [
    'W23',
    'W24',
    'W25',
    'W26',
    'W27',
    'W28',
    'W29',
    'W30',
    'W31',
    'W32',
    'W33',
    'W34',
    'W35',
    'W36',
    'W37',
    'W38',
    'W39',
    'W40',
    'W41',
    'W42',
    'W43',
    'W44',
    'W45',
    'W46',
    'W47',
    'W48',
    'W49',
    'W50',
    'W51',
    'W52',
    'W53',
    'W54',
    ...defaultSizing,
    ...defaultLargeSizing,
  ],
};
