export const brandsUnsorted = [
  'ADRIANO GOLDSCHMIED',
  'GALLIANO',
  'CZARINA',
  'LOVE MOSCHINO',
  'ZOMP',
  'CHRISTIAN DIOR SPORTS',
  'ASILIO',
  'FARM RIO',
  'TUSCANY',
  'BY FAR',
  'KARL LAGERFIELD X PUMA',
  'BAZ',
  'MOSS AND SPY',
  'GIANNI VERSACE',
  'VIVIENNE LORIKEET',
  'BUTTERO',
  'CHANEL',
  'HOLIDAY BY EMMA MUHOLLAND',
  'NATASHA GAN',
  'BCBG MAX AZRIA',
  'STELLA MCCARNTEY',
  'CHANCERY',
  'LISA BARRON',
  'Sir the Label',
  'SISLEY',
  'PEARL AND CAVIAR',
  'ADOLFO DOMINQUEZ',
  'ADAM LIPPES',
  'ARMANU EXCHANGE',
  'ARMANI EXCHANGE',
  'DAMSEL AND SILK',
  'SANCIA',
  'CULT GAIA',
  'KHAITE',
  'ISSA LONDON',
  'COOGI AUSTRALIA',
  'MANSUR GAVRIEL',
  'JOEY',
  'OSVALDO ROSSI',
  'HALE BOB',
  'TORY BURCH',
  'STAUD',
  'ALEJANDRA ALONSO ROJAS',
  "THEYSKENS' THEORY",
  'SCANLAN THEODRE',
  'PALM',
  'VINCE',
  'ATSURO TAYAMA',
  'TAYLOR BOUTIQUE',
  'ALEXIS',
  'PESERICO',
  'MADEME',
  'SOL SANA',
  'GESTUZ',
  'SCOTCH AND SODA',
  'YIGAL AZROUEL',
  'ARMANI',
  'ARMANI JEANS',
  'DOLCE VITA',
  'KARINA GRIMALDI',
  'PAUL FRANK',
  'EASTON PEARSON',
  'COLLECTION PRIVEE',
  'MARYAM NASSIR ZADEH',
  'LU GOLDIE',
  'DSQAURED',
  'TALULAH',
  'AMO',
  'PASDUCHAS',
  'MARC JACOBS',
  'SEA NEW YORK',
  'BIRD AND KITE',
  'DJANGO AND JULIETTE',
  'BALDININI',
  'MISSONI',
  'CARMEN MARC VALVO',
  'ARLINGTON MILNE',
  'ELIE TAHARI',
  'SANTINA NICOLE',
  'ALEMBIKA',
  'COACH',
  'KIANNA',
  'ISLA',
  'HITHERTO',
  'JAYSON BRUNSON BLACK LABEL',
  'BARIANO',
  'JEROME DREYFUSS',
  'NIKE',
  'DRIES VAN NOTEN',
  'OSMAN',
  'CELLINI',
  'VERSACE',
  'ARIES',
  'AUGUSTE',
  'SEA SAW',
  'SEE BY CHLOE',
  'KOOKAI',
  'AGNES B',
  'VELVET BY GRAHAM AND SPENCER',
  'MACKENZIE MODE',
  'GEORGE GROSS',
  'MARC NEW YORK',
  'BAZAR DELUXE',
  'CHIARA FERRAGNI',
  'LONGCHAMP',
  'AURELIO COSTARELLA',
  'MOTHER',
  'COCO RIBBON',
  'NICO GIANI',
  'COCCINELLE',
  'PORTA ROSSA',
  'DAVID LAWRENCE',
  'MO & CO',
  'FIORUCCI',
  'ALT',
  'IRO',
  'VERSACE JEANS COUTURE',
  'LOULOU STUDIO',
  'REBECCA BALDUCCI',
  'HALSTON HERITAGE',
  'WHITE LABEL NOBA',
  'A.W.A.K.E. MODE',
  'ATOIR X LARA WORTHINGTON',
  'POETRY',
  'MELISSA X VIKTOR ROLF',
  'CAZINC THE LABEL',
  'MUGNAI',
  'FILIPPO RAPHAEL EDWARD MELLER',
  'PAS DE ROUGE',
  'WATSON X WATSON',
  'BONDI BORN',
  'MONCLER',
  'LITTLE JOE WOMEN',
  'NEO ROSANNA CONTADINI',
  'MANIERE DE VOIR',
  'MOS MOSH',
  'HOUSE OF SUNNY',
  'CEDRIC CHARLIER',
  'SIREN',
  'MAURO GRIFONI',
  'ANGELA ROI',
  'NINETY PERCENT',
  'SENSO',
  'CURATE BY TRELISE COOPER',
  'OPENING CEREMONY',
  'CHILDE',
  'MLOUYE',
  'OSCAR',
  'HARRIS TAPPER',
  'AERE',
  'RABENS SALONER',
  'BRASHY STUDIOS',
  'ALEXANDER WANG',
  'PADDO TO PALMY',
  'BOTEH',
  'RAG AND BONE',
  '3.1 PHILLIP LIM',
  'GHOST LONDON',
  'JEANNOT',
  'SOPHIE HULME',
  'RALPH LAUREN',
  'AMI ALEXANDRE MATTIUSSI',
  'ROKSANDA',
  'LALTRAMODA',
  'COLE HAAN X NIKE',
  'REISS',
  'OLIVIA RUBIN',
  'DISNEY',
  'CAROLINE LEMKE',
  'LOUIS VUITTON',
  'RJR JOHN ROCHA',
  'MEGAN PARK',
  'NUDIE',
  'TOTEM',
  'PROENZA SCHOULER',
  'ZATCHELS',
  'THE FOLD',
  'PIAZZA GRANDE',
  'BALENCIAGA',
  'NEOUS',
  'SUMMERY COPENHAGEN',
  'ANTEPRIMA',
  'ACLER',
  'ROUCHA',
  'R.M. WILLIAMS',
  'LOUIS FERAUD',
  'PERRI CUTTEN',
  'MORRISON',
  'MILLY',
  'BY NICOLA',
  'CLOVER CANYON',
  'ANNA AND BOY',
  'MACGRAW',
  'CEFINN',
  'AQUAZZURA',
  'PISARRO NIGHTS',
  'NATHALIE DUMEIX',
  'ZAC POSEN',
  'WILLOW',
  'THE ARK',
  'GYPSY 05',
  'MARITHE FRANCOIS GIRBAUD',
  'NOOKIE',
  'MONTE AND LOU',
  'NANUSHKA',
  'BIRKENSTOCK',
  'ROUJE',
  'GEORGINA GOODMAN',
  "SOFIE D'HOORE",
  'GUSHLOW AND COLE',
  'WORLD OF NOMADS',
  'ANINE BING',
  'ANNA THOMAS',
  'MASTANI',
  'EZZIO',
  'HAUTE HIPPIE',
  "L'AUTRE CHOSE",
  'MONTIQUE',
  'RACHEL COMEY',
  'MICKAEL AGHAL',
  'GUY LAROCHE',
  'SAMBAG',
  'PATRIZIA PEPE',
  'COSTUME NATIONAL',
  'ROYAL REPUBLIQ',
  'PLEATSE PLEASE',
  'MARNIE SKILLINGS',
  'WELLINGTON FACTORY',
  'THE WOLFGANG',
  'M.I.H JEANS',
  'GOLDEN GOOSE DELUXE BRAND',
  'HAUS OF PBK',
  'KEEPSAKE',
  'ANDREA AND JOEN',
  'PORTS 1961',
  'NU DENMARK',
  'CHRISITAN LOUBOUTIN',
  'GIA/RHW',
  'ATOS LOMBARDINI',
  'FRAME',
  'KATE SPADE',
  'KAT MACONIE',
  'ONE FELL SWOOP',
  'SUBOO',
  'MARISSA WEBB',
  'EMME PARSONS',
  'MATT AND NAT',
  'HARRY AUSTIN',
  'HUNTER',
  'ARNHEM',
  'TOMS',
  'ROTATE BIRGER CHRISTENSEN',
  'COSTER COPENHAGEN',
  'STORM',
  'GINIA RTW',
  'JAFRIE BY R',
  'PETERSYN',
  'FLAPPER BY GENEVIEVE XHAET',
  'KIVARI',
  'STRATEAS CARLUCCI',
  'M.A. DAINTY',
  'DIANE VON FUSTENBERG',
  'NOBODY',
  'RICK OWENS',
  'OSKA',
  'THE HORSE',
  'GERARD DAREL',
  'MAGALI PASCAL',
  'WHITE VERVE',
  'ELIYA',
  'LULU YASMINE',
  'SALONI',
  'JONATHAN SIMKHAI',
  'APESI',
  'AARON AUNSOLO',
  'SPORTMAX',
  'UNDERCOVER',
  'HOLLAND COOPER',
  'TOOTSIES',
  'BIANCA BUCCHERI',
  'LACOSTE',
  'EMPORIO ARMANI',
  'POSSE',
  'OTTOLINGER',
  'VON ROUTTE',
  'JEAN PAUL GAULTIER FEMME',
  'BINNY',
  'WHIM GAZETTE',
  'ECCO',
  'LOEFFLER RANDALL',
  'BIRD AND KNOLL',
  'SABINA MUSAYEV',
  'BOSS HUGO BOSS',
  'THEORY',
  'MAGGIE MARILYN',
  'CHARLES NOLAN',
  'CARA CARA',
  'SKETA',
  'PAUL ANDREW',
  'CARVELA',
  'BVLGARI',
  'ASCENO',
  'JAKKE',
  'TIBI',
  'ESTILO EMPORIO',
  'PAOLO MASI',
  'APIECE APART',
  'ANNA QUAN',
  'AMANDA UPRICHARD',
  'TORANNCE',
  'K. JACQUES',
  'ROWIE',
  'MISA',
  'DAVID POND',
  'OOTD AME',
  'BOUTIQUE MOSCHINO',
  'MARIE FRANCE VAN DAMME',
  'FABIANA FILIPPI',
  'CHARO RIUZ',
  'VERONIQUE BRANQUINHO',
  'AQUILANO RIMONDI',
  'L.K. BENNETT',
  'KATHERINE HAMNETT',
  'MCQ ALEXANDER MCQUEEN',
  'SARAH JANE CLARKE',
  'CAMILLA',
  'VIVIENNE WESTWOOD ANGLOMANIA',
  'MIDAS',
  'LIDEE',
  'ENA PELLY',
  'DAVID JONES',
  'FAME AND PARTNERS',
  'THAKOON ADDITION',
  'KITX',
  'MILANA',
  'CASADEI',
  'POLLY LISSIMAN',
  'STEFANO STEFANI',
  'JENNIFER KATE',
  'TIGERLILY',
  'PETER SHEPPARD',
  'PFEIFFER',
  'XIRENA',
  'BODEN',
  'NAGNATA',
  'BUSCHEMI',
  'BOHEMIAN TRADERS',
  'ELKA COLLECTIVE',
  'WITH JEAN',
  'REBECCA VALLANCE',
  'Roxy',
  'A.L.C.',
  'GINIA',
  'PARIS GEORGIA',
  'CECILIE COPENHAGEN',
  'TOMMY JEANS',
  'ALISTAIR TRUNG',
  'REJINA PYO',
  'ERIKA PENA',
  'MARIMEKKO',
  'MIU MIU',
  'DOLCE AND GABBANA',
  'MAGDALENA VELEVSKA',
  'ACNE',
  'BOUNCE',
  'YEEZY',
  'ANNE CLAIRE',
  'HI THERE KAREN WALKER',
  'MICHAEL VAN DER HAM',
  'TARA JARMON',
  'MAX & CO',
  'TWOOBS',
  'CAMPER',
  'JEFFREY CAMPBELL',
  'MSGM',
  'ELLIATT',
  'VALDITEVERE',
  'OWNLEY',
  'TRENT NATHAN',
  'COMME DES GARCONS',
  'METISEKO',
  'ZADIG AND VOLTAIRE',
  'NUDIE JEANS',
  'NEVER FULLY DRESSED',
  'BOSS ORANGE',
  'THE SILK ROBE',
  'JAMES SMITH',
  'AMELIUS',
  'KAREN WAKER',
  'COAST',
  'CARRIE FORBES',
  'RAOUL',
  'GIANMARCO LORENZI',
  'FENDI',
  'EILEEN FISHER',
  'HENRI BENDEL',
  'CANADA GOOSE',
  'ERDEM',
  'LUISA CERANO',
  'PIERRE BALMAIN',
  'MARA HOFFMAN',
  'GEMELI POWER',
  'TRELISE COOPER',
  'KATE SYLVESTER',
  'CO',
  'DEREK LAM',
  'WORLD',
  'B-LOW THE BELT',
  'VICTORIA VICTORIA BECKHAN',
  'ISABEL MARANT ETOILE',
  'TED BAKER',
  'NO 21',
  'KSUBI',
  'LUNA ATELIER',
  'SAVAS',
  'NINA RICCI',
  'MAISON SCOTCH',
  'RACHEL GILBERT',
  'EQUIPMENT',
  'MATTEAU',
  'HUSK',
  'DOEN',
  'ST MRLO',
  'EDIT BY LEE MATHEWS',
  'MEXX',
  'ANNE FONTAINE',
  'PAULA RYAN',
  'HOSS INTROPIA',
  'MARA AND MINE',
  'OSCAR DE LA RENTA',
  'ALEXANDRA',
  'DAY BIRGER ET MIKKELSEN',
  'CLOSED',
  'AJE',
  'LAUREL',
  'WEEKEND MAX MARA',
  'TARA MATHEWS',
  'AJE ATHLETICA',
  'HARMONT AND BLAINE',
  'WANT LES ESSENTIALS',
  'PERSOL',
  'LORIBLUE',
  'BACCARAT',
  'DIESEL BLACK GOLD',
  'CHRISTIAN SIRIANO',
  'CARVER',
  'TOMMY HILFIGER',
  'ILSE JACOBSEN',
  'NIQUE',
  'PINKO',
  'ILARIA NISTRI',
  'BY JOHNNY',
  'SEVENTH WONDERLAND',
  'KENNETH COLE',
  'STAPLE THE LABEL',
  'CHARLOTTE OLYMPIA',
  'STEELE',
  'INC BAZ',
  'TULAROSA',
  'CLARE VIVIER',
  'LES REVERIES',
  'MARC CAIN',
  'YOHJI YAMAMOTO',
  'WITCHERY',
  'NICOLE MILLER ATELIER',
  'EDWARD MELLER',
  'CATHERINE MURPHY',
  'ALL SAINTS SPITAFIELD',
  'KOSMIKA',
  'CHARLI COHEN',
  'MARNI',
  'TACH',
  'MARCELO BURLON',
  'MICHAEL LO SORDO',
  'PANGAIA',
  'WE11DONE',
  'FRIENDS WITH FRANK',
  'ALICE AND OLIVIA',
  'FRIEND OF AUDREY',
  'MARQUES ALMEIDA',
  'THOM BROWNE',
  'LEONA EDMISTON',
  'HELMUT LANG',
  'HOFMANN COPENHAGEN',
  'RIXO',
  'JIGSAW',
  'CHARLES MIDDLETON',
  'CABLE MELBOURNE',
  'LA FESTIN',
  'SASS AND BIDE',
  'BRUNELLO CUCINELLI',
  'WYSE',
  'GUCCI',
  'SOLACE LONDON',
  'COLVILLE',
  'ELLERY',
  'ETRO',
  'MARC BY MARC JACOBS',
  'H BRAND',
  'DEREK LAM 10 CROSBY',
  'BA&SH',
  'BEATRICE .B',
  'ZANDRA RHODES',
  'SELF PORTRAIT',
  'SONYA',
  'TSUMORI CHISATO',
  'REBECCA TAYLOR',
  'LITTLE JOE',
  'PREEN BY THORNTON BREGAZZI',
  'M.M.LAFLEUR',
  'AMINA MUADDI',
  'YVES SAINT LAURENT',
  'BIMBA Y LOLA',
  'WUNDERKIND',
  'ULLA JOHNSON',
  'CABLE',
  'ROMANCE WAS BORN',
  'ZK BY ZOE KRATZMANN',
  'STINE GOYA',
  'FUZZI',
  'BASLER',
  'ART DEALER',
  'LIFE WITH BIRD',
  'CARLA ZAMPATTI',
  'TUMI',
  'AQUATALIA',
  'KONSTANTIN STARKE',
  'NELSON MADE',
  'HUNKYDORY',
  'GRACE WILLOW',
  'ACNE JEANS',
  'BEAU COOPS',
  'CLERGERIE',
  'SOMETHING 4 OLIVIA',
  'VALENTINO',
  'BURBERRY',
  'FAITHFULL THE BRAND',
  'PAPILLIO',
  'BY FREER',
  'HANNAH',
  "DOMINIQUE'S",
  'STUDIBAKER',
  'CARCEL',
  'PIAZZA SEMPIONE',
  'BLUE ILLUSION',
  'BIG BUD PRESS',
  'CESARE PACIOTTI',
  'KOBI HALPERIN',
  'MICHAEL KORS',
  'WYNN HAMLYN',
  'FOR LOVE AND LEMONS',
  'GENTLE MONSTER',
  'MOMONI',
  'LISA HO',
  'STELLA MCCARTNEY FOR ADIDAS',
  'MINISTRY OF STYLE',
  'KACEY DEVLIN',
  'MAEVE',
  'SANDRA SOULOS',
  'DEIJI STUDIOS',
  'REBECCA MINKOFF',
  'BORGO DE NOR',
  'ROBERT CLERGERIE',
  'RAFE',
  'LILITH',
  'MISS V VALENTINO',
  'MARTINI OSVALDO',
  'MASSIMO DUTTI',
  'CAMILLA COELHO',
  'STUART WEITZMAN',
  'ALANNAH HILL',
  'LEE MATHEWS',
  'JAC + JACK',
  'ELTON JOHN FOR REALISATION',
  '2NNDAY',
  'JOIE',
  'W118 BY WALKER BAKER',
  'PAUW',
  'WALTER STEIGER',
  'SWAROVSKI',
  'VALENTINO BOUTIQUE',
  'GISELE',
  'AKRIS PUNTO',
  'EMILIA WICKSTEAD',
  'SELECTED FEMME',
  'SKIN AND THREADS',
  'VERSUS VERSACE',
  'ANCIENT GREEK SANDALS',
  'GIUSEPPE ZANOTTI DESIGN',
  'CAMEO',
  'ALICE MCALL',
  'VERONIKA MAINE',
  'DIEGO DOLCINI',
  'ELIZABETH AND JAMES',
  'MOSCHINO JEANS',
  'DEIJI',
  'JUICY COUTURE',
  'SIES MARJAN',
  'MAX MARA',
  'AVENUE',
  'INGRID STARNES',
  'MAISON MARGIELA',
  'MADRILENA',
  'MATHEW WILLIAMSON',
  'MAGGIE THE LABEL',
  'AHLUWALIA',
  'JOSEPH RIBKOFF',
  'VERA WANG LAVENDER',
  'ROTATE',
  'ONCE WAS',
  'KALMANOVICH',
  'TONY BIANCO',
  'SERGIO ROSSi',
  'MARILYN SAINTY',
  'TIGERILY',
  'DOUBLE RAINBOUU',
  'ECLECT',
  'NEUW',
  'MALONE SOULIERS',
  'ZULU AND ZEPHYR',
  'HALSTON',
  'VENROY',
  'PALM NOOSA',
  'BALLY',
  'BALMAIN',
  'PERSEVERANCE',
  'TALBOT RUNHOF',
  'BONITA',
  'CHINITI AND PARKER',
  'ELLE ZEITOUNE',
  'SONIA RYKIEL',
  'PAULE KA',
  'REVIEW',
  'THE ATTICO',
  'ISA TAPIA',
  'FALCONERI',
  'IH NOM UH NIT',
  'STEVIE MAY',
  'GREY BY JASON WU',
  'PARAKIAN',
  'PRADA',
  'ZOE KARSSEN',
  'FLAVIO CASTELLANI',
  'LAYFAYETTE 148',
  'LEO LIN',
  'VEJA',
  'ARMANO COLLEZIONI',
  'HUISHAW ZHANG',
  'SEASIDE TONES',
  'SEED',
  'WE ARE KINDRED',
  'MOSCHINO CHEAP AND CHIC',
  'JORDACHE',
  'CHRISTOPHER KANE',
  'ALEXA CHUNG',
  'JO MERCER',
  'SEZANE',
  'CAMI NYC',
  'JAMES PERSE',
  'RASARIO',
  'BY NICOLA FINETTI',
  'ZIMMERMANN',
  'LANVIN',
  "D'ARTEMIDE",
  'SECOND FEMALE',
  'MAURIE AND EVE',
  'ROBERTO TORRETTA',
  'LINDSAY NICHOLAS',
  'MUNTHE',
  '1017 ALYX 9SM',
  'HUGO HUGO BOSS',
  'REFORMATION',
  'CAES',
  'ANDREA MABIANI',
  'ST JOHN COUTURE',
  'FARM',
  'ALIAS MAE',
  'ALEXANDRE BIRMAN',
  'KACHEL',
  'HANNES ROETHER',
  'STAPLE AND LABEL',
  'ADIDAS',
  'ALVIERO MARTINI',
  'UNITED COLORS OF BENETTON',
  'RE/DONE',
  'SOCIALIGHT',
  'BY MALENE BIRGER',
  'FLAX',
  'RENA LANGE',
  'ANN TAYLOR',
  'NIKE AIR',
  'SNIDEL',
  'MALIZIA BY LA PERLA',
  'SAYLOR',
  'SAMSOE SAMSOE',
  'STONE ISLAND',
  'GOLDEN GOOSE',
  'DOONEY AND BOURKE',
  'FRNC',
  'JOHANNA ORTIZ',
  'LIKELY',
  'LOVESHACKFANCY',
  'ROBERT RODRIGUEZ',
  'BAUM UND PFERDGARTEN',
  'MAISON POI',
  'FILIPPO RAPHAEL',
  'CALYPSO ST BARTH',
  'MAISON HOTEL',
  'PIER ANTONIO GASPARI',
  'CAROLINA HERRERA',
  'BALDAN',
  'JUST',
  'ALEMAIS',
  'THEIA',
  'RAT AND BOA',
  'BEULAH',
  'VIVIENNE WESTWOOD',
  'LOEWE',
  'SACHA DRAKE',
  'ALQUEMA',
  'PIANURASTUDIO',
  'MATIN',
  'HOUSE OF HARLOW 1960',
  'JOHN FLUEVOG',
  'ALEXANDER MCQUEEN',
  'PALM ANGELS',
  'PIPPA HOLT',
  'RUPERT SANDERON',
  'BRIGID MCLAUGHLIN',
  'BRONX AND BANCO',
  'FLEUR WOOD',
  'CURRENT/ELLIOTT',
  'AQUA BY AGUA BENDITA',
  'MES DEMOISELLES',
  'MCM',
  'WHISTLES',
  'RACHEL ALEX',
  'SHONA JOY',
  'ANTHEA CRAWFORD',
  'MARCHEN',
  'COMMON PROJECTS',
  'DODO BAR OR',
  'VITAMIN A',
  'RAILS',
  'DONNA DONNA',
  'DISSH',
  'NICHOLAS KIRKWOOD',
  'FOSSIL',
  'DION LEE',
  'ALEX PERRY',
  'VERONICA BEARD',
  'INOA',
  'MARYAN MEHLHORN',
  'FERAUD',
  'ALAIA',
  'CHRISTIAN DI RICCIO',
  'SUNDAY SOMEWHERE',
  'THE FIFTH LABEL',
  'FRENCH CONNECTION',
  'AILANTO',
  'VELHA',
  'THURLEY',
  'TOM FORD',
  'RADLEY LONDON',
  'BIANCA AND BRIDGETT',
  'AN HOUR AND A SHOWER',
  'ALYSI',
  'JILLIAN BOUSTRED',
  'LISA MARIE FERNANDEZ',
  'LMND',
  'SANDOR',
  'VICTORIA BECKHAM JEANS',
  'KINNEY',
  'OSKER',
  'PISTOLA',
  'ET CETERA',
  'PHILOSOPHY DI ALBERTA FERRETI',
  'HUMIDITY',
  'PEACHOO AND KREJBERG',
  'NICOLE MILLER',
  'AXEL ARIGATO',
  'JOSLIN',
  'DEAR FRANCES',
  'FRANCOISE ESTERHAZY',
  'GIORGIO ARMANI',
  'UNREAL FUR',
  'DKNY JEANS',
  'ZJOOSH',
  'MIISTA',
  'BEC AND BRIDGE',
  'SU',
  'BLANCA',
  'ESSENTIEL ANTWERP',
  'PEDRO GARCIA',
  'REPETTO',
  'STEFANEL',
  'GIANVITO ROSSI',
  'CHOSEN',
  'PETER PILOTTO',
  'THIRD FORM',
  'STELLA LUNA',
  'RAY-BAN',
  'BRAHMIN',
  'SWEDISH HASBEENS',
  'KSENIA SCHNAIDER',
  'DYLAN KAIN',
  'SOLID AND STRIPED',
  'PAPER LONDON',
  'TRENERY',
  'MAISON KITSUNE X ADER ERROR',
  'RADLEY',
  'CHLOE',
  'AEYDE',
  'GIAMPAOLO',
  'MELISSA ODABASH',
  'LIDA BADAY',
  'ESSE',
  'COLLETTE DINNIGAN',
  'VERSACE COLLECTION',
  "L'ACADEMIE",
  'HIGH EVERYDAY COUTURE',
  'CAMPO MARZIO',
  'BRIAN ATWOOD',
  'CHARLIE BROWN',
  'PIERRE CARDIN',
  'SPELL AND THE GYPSY',
  'AN JOUR LE JOUR',
  'SPORTSCRAFT',
  'DIANE FREIS ORIGINAL',
  'CAVERLEY',
  'FILLYBOO',
  'NATALIE ROLT',
  'POL',
  'ANNA HOFFMANN',
  'MAISIE WILEN',
  'AGOLDE',
  'JEREMY SCOTT',
  'EMILIO PUCCI',
  'BAMBAH',
  'CECILIA PRADO',
  'TEMPERLEY',
  'KALITA',
  'BIONDA CASTANA',
  'UNITED NUDE',
  'POLO BY RALPH LAUREN',
  'THE ROW',
  'NOVELLA',
  'CHALAYAN',
  'IXIAH\ufffd\ufffd',
  'ALEKSANDRE AKHALKATSISHVILI',
  'RALPH LAUREN SPORT',
  'CUE',
  'FUNKIS',
  'MORRISSEY',
  'MELA PURDIE',
  'MATICEVSKI',
  'MASINI AND CHERN',
  'RHODE RESORT',
  "EVA'S SUNDAY",
  'KIRRILY JOHNSTONE',
  'VANISHING ELEPHANT',
  'HOUSE OF HOLLAND',
  'LUCETTE',
  'DRKSHDW',
  'SIR',
  'LEMAIRE',
  'KAREN MILLEN',
  'SIMON MILLER',
  'THREE FLOOR',
  'MARINA SPORT',
  'STONE COLD FOX',
  'GIVENCHY',
  'LA BOHEME GIRLS',
  'FAUSTO PUGLISI',
  'KING CSILLA',
  'CHIEF STUDIO',
  'BOTKIER',
  'FORTE FORTE',
  'AGUA BENDITA',
  'A.P.C.',
  'JETS BY JESSIKA ALLEN',
  'GALVAN',
  'HAIDER ACKERMANN',
  'PRINGLE OF SCOTLAND',
  'ANNA KOSTUROVA',
  'RODEO SHOW',
  'DIZENGOFF',
  'MARLE',
  'THE OTHERS',
  'SABATINI',
  'ELK',
  'JANE POST',
  'Y.A.S',
  'DONNYBROOK',
  'ST AGNI',
  'OBUS',
  'DR MARTENS',
  'CALONGE',
  'NATALIE ALAMEIN',
  'LAURA BELLARIVA',
  'LORBAC',
  'GIAMBATTISTA VALLI',
  'NARA CAMICIE',
  'LOVER',
  'ANNA SUI',
  'GIANFRANCO FERRE',
  'J.O.A',
  'ATP ATELIER',
  'LOLA AUSTRALIA',
  'JONAK',
  'JIMMY CHOO',
  'TAHARI',
  'MARION PARKE',
  'BCBGENERATION',
  'JOSEPH',
  'EDUN',
  'HERON PRESTON',
  'NATASHA ZINKO',
  'SPELL',
  'THE CAMBRIDGE SATCHEL',
  'VIVIENNE WESTWOOD RED LABEL',
  'DUFFY',
  'SOVERE',
  'VIKTORIA AND WOODS',
  'CARL KAPP',
  'CITIZENS OF HUMANITY',
  'AGGI',
  'HERMES',
  'HANNAH ARTWEAR',
  'CHIO DI STEFANIA D',
  'MAJORELLE',
  'CYNTHIA ROWLEY',
  'ISOLA MARRAS',
  'THREE GRACES',
  'AQ/AQ',
  'FRANCESCA BETTINI',
  'GEDEBE',
  'GOOD AMERICAN',
  'PREEN LINE',
  'LUXE DELUXE',
  'AIJEK',
  'CHARLIE HOLIDAY',
  'EMANUEL UNGARO',
  'CAROLINE CONSTAS',
  'HEIDI KLEIN',
  'ERMANO SCERVINO',
  'LES COYOTES',
  'SIGNIFICANT OTHER',
  'ONE SEASON',
  'P.E NATION',
  'LE SILLA',
  'G.D.S',
  'THE KOOPLES',
  'NOBODY DENIM',
  'JOHANSEN',
  'POST SOLE STUDIO',
  'DKNY SPORT',
  'KRIS VAN ASSCHE',
  'ROGER VIVIER',
  'TRUE RELIGION',
  'ONTE',
  'NICE MARTIN',
  'MORTON MAC',
  'OOGI',
  'JAYSON BRUNSDON',
  'MARCS',
  'CHRISTIAN LACROIX',
  'TARNI EILEEN',
  'ZOE KRATSMANN',
  'ALL SAINTS',
  'TABITHA SIMMONS',
  'ESCADA',
  'GOODWIN CHARLI',
  'WHITE SUEDE',
  'ANNE KLEIN',
  'INNIKA CHOO',
  'WAYNE COOPER',
  '& OTHER STORIES',
  'MARCHESA NOTTE',
  'BIANCA SPENDER',
  'LAUNDRY BY SHELLI SEGAL',
  'SPARROWS WEAVE',
  'NICOLA WAITE',
  'DESIGUAL',
  'AALTO',
  'DIESEL',
  'MIMCO',
  'MILEA',
  'LA DOUBLE J',
  'MATSHIDA',
  'JUST CAVALLI',
  'PILCRO BY ANTROLPOLOGIE',
  'OROTON',
  'INCU',
  'AUTEUR',
  'ARNSDORF',
  'THAKOON',
  'REALISATION',
  'MANNING CARTELL',
  'KARLA SPECTIC',
  'J CREW',
  'BALTARINI',
  'JOHNNY WAS',
  'MANOLO BLAHNIK',
  'TARA LANGFIELD',
  'RESUME',
  'SIMPLY VERA WANG',
  'DAGMAR',
  'ALPHA 60',
  'OLIVER CABELL',
  'SARAH JANE CURTIS',
  'SHAKUHACHI',
  'STATUS ANXIETY',
  'ME + EM',
  'KENZO',
  'BEACH BUNNY',
  "TOD'S",
  'RAEF',
  'GANNI',
  'NOA NOA',
  'ANN DEMEULEMEESTER',
  'BOYISH JEANS',
  'SEASIDE',
  'JILL STUART',
  'FRANCOIS PINET',
  'LE SALTY LABEL',
  'SLEEPER',
  'NATACHA',
  'GINGER AND SMART',
  'FINDERS KEEPERS',
  'MISTER ZIMi',
  'TY-LR',
  'MISS SIXTY',
  'LA PERLA',
  'DHARMA DOOR',
  'THE DREAMER LABEL',
  'GARY BIGENI',
  'JETS',
  'LOUIS CARDINI',
  'CHRISTOPHER ESBER',
  'LAILA AZHAR',
  'ROBERTO CAVALLi',
  'FURLA',
  'ESCADA SPORT',
  'TOMMY BAHAMA',
  'RITMO',
  'ALL THAT REMAINS',
  'LOVERS AND FRIENDS',
  'MULBERRY',
  'BOTTEGA VENETA',
  'CLAUDIE PIERLOT',
  'UMA AND LEOPOLD',
  'EYTYS',
  'OXFORD',
  'RYDER',
  'CINQ A SEPT',
  'ROBERT ROBERT',
  'BARBARA BUI',
  'GORMAN',
  'HERVE LEGER',
  'STUTTERHEIM',
  'LUISA SPAGNOLO',
  'ENKI',
  'NINA ARMANDO',
  'DONDUP',
  "ALESSANDRO DELL'ACQUA Boots",
  'PACO RABANNE',
  'AESTHER EKME',
  'HELEN KAMINSKI',
  'SAROKA',
  'BUL',
  'FREE PEOPLE',
  "JEN'S PIRATE BOOTY",
  'HABBOT',
  'SOPHIA WEBSTER',
  'PAUL SMITH',
  'BASSIKE',
  'SIMONA',
  'HANSEN AND GRETEL',
  'MON RENN',
  'ISSEY MIYAKE',
  'RUTH TARVYDAS',
  'MINIMA ESENCIALES',
  'DKNYC',
  'R & RENZI',
  'GARY CASTLES',
  'THE UPSIDE',
  'LA PRESTIC OUISTON',
  'LES COYOTES DE PARIS',
  'PALMER / HARDING',
  'FLANNEL',
  'CHARLES JOURDAN',
  'CASTANER',
  'SUNCOO',
  'COUNTRY ROAD',
  'KURT GEIGER',
  'DIIDA',
  'C/MEO COLLECTIVE',
  'PERFECT MOMENT',
  'FINDERS',
  'LUXE DE VALENTINA',
  'JOSH GOOT',
  'LACAUSA',
  'P.A.R.O.S.H',
  'R/R',
  'RUMIA',
  'POLENE',
  'COOPER',
  'RUSSEL AND BROMLEY',
  'EVERLANE',
  'VANESSA BRUNO',
  'VESTIRE',
  'ROLAND MOURET',
  'ROBERT PIETRI',
  'HOGAN',
  "L'AGENCE",
  'GAALA',
  'RAF SIMONS',
  'JULIE FAGERHOLT HEARTMADE',
  'LAURA ASHLEY',
  'STRATEGIA',
  'BEAUFILLE',
  'HOSS',
  'JIL SANDER',
  'INTERMIX',
  'HARRIS WHARF LONDON',
  'ZOE JORDAN',
  'LA STRADA',
  'NOBODY JEANS',
  'SUPREME X THE NORTH FACE',
  'PEONY',
  'MARKUS LUPFER',
  'ARTHUR GALAN',
  'MISS CIRCLE',
  'NICHOLAS',
  'ETRE CECILE',
  'LAUREN RALPH LAUREN',
  'RUE STIIC',
  'CALLI',
  'MARCHESA VOYAGE',
  'ESTEBAN CORTAZAR',
  'MICHELLE MASON',
  'ASSEMBLY LABEL',
  'EACH X OTHER',
  'LILLIAN KHALLOUF',
  'SIGERSON MORRISON',
  'MOSCHINO COUTURE',
  'SOPHIE MORAN',
  'PAIGE',
  'SEAFOLLY',
  'IOANNA KOURBELA',
  'ADIDAS BY STELLA MCCARTNEY',
  'A.P.C RUE MADAME PARIS',
  'SALVATORE FERRAGAMO',
  'PALOMA BARCELO',
  'LAVENDER LABEL VERA WANG',
  'FARAGE',
  'MARYSIA',
  'I.N.K',
  'COLE HAAN',
  'ALPHA STUDIO',
  'DIOR',
  'MISHA COLLECTION',
  'AVRIL GAU',
  'BANDED TOGETHER',
  'MLM',
  'NATALIE CHAPMAN',
  'ANTONIO MELANI',
  'LOQ',
  'BRUNO MAGLI',
  'GORMAN X PANTONE',
  'UTERQUE',
  'ALBERTA FERRETTI',
  'CELINE',
  'NAKEDVICE',
  'ZUCCA',
  'GOEN.J',
  'AMBALI',
  'WOLFORD',
  'ANTHROPOLOGIE',
  'CALLISTA',
  'SCANLAN THEODODRE',
  'SCHUTZ',
  'RICHARD NICOLL',
  'T BY ALEXANDER WANG',
  'CAMILLA AND MARC',
  'MARTIN GRANT',
  'RODEBJER',
  'AKRIS BERGDORF GOODMAN',
  'UGG',
  'STEVE MADDEN',
  'ACNE STUDIOS',
  'CLUB MONACO',
  'GABRIELA HEARST',
  'CHEMISE LACOSTE',
  'NO BRAND',
  'NO LABEL',
];

// sort brands a-z
brandsUnsorted.sort((a, b) => a.localeCompare(b));

// function to export brand object with two keys option and label both the same
export const brandsObject = brandsUnsorted.map(brand => ({
  option: brand,
  label: brand,
}));

export const brands = brandsUnsorted;
